import { FC, ReactNode, useEffect, useState } from 'react';
import { useSpring, animated } from 'react-spring';
import { useInView } from 'react-intersection-observer';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

type AnimatedComponentProps = {
    children: ReactNode;
};

const AnimatedComponent: FC<AnimatedComponentProps> = ({ children }) => {
    const [ref, inView] = useInView({
        triggerOnce: true,
        rootMargin: '-100px 0px',
    });

    const animation = useSpring({
        opacity: inView ? 1 : 0,
        transform: inView ? 'translateY(0)' : 'translateY(100px)',
        config: { mass: 5, tension: 1000, friction: 80 },
    });

    return <animated.div ref={ref} style={animation}>{children}</animated.div>;
};
type BlogPostProps = {
    id: number,
    title: string;
    content: string;
    image: string;
    creator_name: string;
    created_at: string;
    time_to_read: string;
};
const Blog = () => {
    const { id } = useParams();
    const [post, setPost] = useState<BlogPostProps>();

    const navigate = useNavigate();

    useEffect(() => {
      window.scrollTo(0, 0);

        try {
            axios.get(`https://api.contasmurf.com.br/posts/${id}`)
              .then(response => {
                if (response.status === 200 && response.data) {
                  setPost(response.data);
                } else {
                    navigate("/posts")
                }
              })
        } catch (error) {
            navigate("/posts")
        }
    }, [id, navigate]);
    return (
        <>
            {post !== undefined && (
                <>
                    <Header />
                    <div className="relative min-h-screen bg-[#07172f] flex items-center justify-center">
                        <AnimatedComponent>
                            <div className="text-white max-w-[600px] mt-60 mx-auto px-4 mb-40">
                                <h1 className="text-4xl font-bold mb-4">{post.title}</h1>
                                <p className="text-lg">
                                    {post.creator_name}
                                </p>
                                <p className="text-lg">
                                    {new Date(post.created_at).toLocaleDateString('pt-BR')} · {post.time_to_read} min de leitura
                                </p>

                                <img src={post.image} alt="Imagem do post" className="mb-6 mt-6" />
                                <p className="text-base mt-4" dangerouslySetInnerHTML={{ __html: post.content.replace(/<\/br>/g, "<br />") }} >
                                </p>
                            </div>
                        </AnimatedComponent>
                    </div>
                    <Footer />
                </>
            )}
        </>
    );
};

export default Blog;
