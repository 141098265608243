import { toast } from 'react-toastify';
import { useEffect } from 'react';
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { MdCheckCircle } from 'react-icons/md';

const Success = () => {

    useEffect(() => {
      toast.success('Pagamento foi feito com sucesso! Verifique o seu e-mail (todas as caixas de entrada)');
    }, []);
  
    return (
      <>
        <Header />
        <div className="relative min-h-screen bg-[#07172f] flex flex-col justify-center items-center text-white">
          <MdCheckCircle size="100" className="text-green-400" /> {/* Ícone de sucesso */}
          <h1 className="text-center px-4 md:px-0 max-w-2xl text-2xl md:text-4xl font-semibold mt-4">
            Compra Confirmada!
          </h1>
          <p className="text-center mt-2 px-4 md:px-0 max-w-lg text-lg">
            Sua compra foi confirmada e o produto entregue. Verifique seu e-mail para mais detalhes.
          </p>
        </div>
        <Footer />
      </>
    );
  };
  
  export default Success;