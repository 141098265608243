import React, { useState } from 'react';
import { MdExpandMore, MdExpandLess } from 'react-icons/md';

type FAQItemProps = {
  question: string;
  answer: string;
};

const FAQItem: React.FC<FAQItemProps> = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="bg-[#0E233B] p-4 rounded-lg">
      <button
        className="w-full text-left flex justify-between items-center py-4 px-6"
        onClick={() => setIsOpen(!isOpen)}
      >
        <span className="text-xl text-white">{question}</span>
        {isOpen ? <MdExpandLess size="24" color="white" /> : <MdExpandMore size="24" color="white" />}
      </button>
      {isOpen && (
        <div className="text-white py-4 px-6">
          {answer}
        </div>
      )}
    </div>
  );
};

type FAQSectionProps = {
  faqList: { question: string; answer: string; }[];
};

const FAQSection: React.FC<FAQSectionProps> = ({ faqList }) => {
  return (
    <div id="faq" className="min-h-screen w-full flex flex-col items-center justify-center p-8 bg-[#07172F]">
      <h2 className="text-3xl text-white text-center font-bold mb-10">FREQUENT ANSWERS AND QUESTIONS (FAQ)</h2>
      <div className="w-10/12 md:w-9/12 space-y-4">
        {faqList.map((faq, index) => (
          <FAQItem key={index} question={faq.question} answer={faq.answer} />
        ))}
      </div>
    </div>
  );
};

const faqData = [
  {
    question: 'O que é uma conta Inativa ou NFA?',
    answer: 'Uma conta inativa ou NFA, que significa Not Full Acess (Sem acesso total). É uma conta de jogo que possui apenas o login e a senha. Essas contas possuem e-mail verificado, mas o dono não as utiliza há bastante tempo. As contas inativas são frequentemente usadas por pessoas que desejam usar scripts, realizar duojob, testar exploits ou até mesmo fazer brincadeiras. No entanto, lembre-se de que as contas NFA não são suas de verdade, você apenas paga para ter acesso a elas. Para passar as contas para o seu email e torna-las de fato suas, basta comprar o combo apocalypse.',
  },
  {
    question: 'As contas possuem garantia?',
    answer: 'Nós oferecemos uma garantia vitalícia para todas as contas marcadas como imunes a banimento. Isso significa que, caso você enfrente algum problema relacionado a banimentos, basta abrir um ticket de suporte e nós iremos fornecer outra conta ou realizar o reembolso do valor pago. ',
  },
  {
    question: 'Contas Upadas a mão / Handlevel',
    answer: 'As contas upadas a mão, também conhecidas como handlevel, são niveladas por nossos habilidosos niveladores profissionais, sem o uso de programas ou bots de terceiros. Essas contas são upadas manualmente, garantindo um MMR neutro e um risco zero de banimento. São ideais para aqueles que planejam ter uma conta a longo prazo, pois oferecem total segurança devido aos nossos niveladores dedicam tempo e esforço para garantir que cada conta handlevel seja de alta qualidade e atenda às expectativas dos nossos clientes.',
  },
  {
    question: 'O que devo fazer após comprar uma conta Full Acesso ?',
    answer: 'Após comprar uma conta Full Acesso, é importante seguir algumas etapas para garantir a segurança e a melhor experiência possível. Assim que receber a sua conta, certifique-se de registrá-la a um e-mail seguro. (De preferência, nunca antes utilizado em uma conta de League of Legends). Em seguida, altere imediatamente a senha da conta no link: https://account.riotgames.com/. Além disso, faça a troca do seu nickname (nome de invocador) ANTES de jogar QUALQUER partida. É recomendado utilizar EA (Essência Azul) para a troca. Você pode alterar o RIOT ID (com #) utilizando o link: https://account.riotgames.com/riot-id/change. Faça o login na sua conta, coloque o novo RIOT ID e #, e clique em SALVAR ALTERAÇÕES.',
  },
  {
    question: 'É seguro comprar aqui ?',
    answer: 'Absolutamente! Aqui na ContaSmurf, a segurança dos nossos clientes é a nossa prioridade número um. Nós nos esforçamos para garantir que todas as contas que oferecemos sejam seguras e livres de qualquer risco de banimento. Além disso, utilizamos o MercadoPago como forma de processar todas as compras, o que garante a segurança das transações financeiras. No entanto, é importante lembrar que a compra de contas vai contra os termos da Riot Games e, portanto, você está sujeito a suspensões e/ou banimentos, dependendo de como você se comporta nas partidas. Recomendamos sempre seguir as diretrizes e políticas da Riot Games para evitar quaisquer problemas.',
  },
];

const FAQ: React.FC = () => {
  return <FAQSection faqList={faqData} />;
};

export default FAQ;
