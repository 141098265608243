import React from 'react';
import { FaFacebook, FaTwitter, FaInstagram, FaTiktok, FaDiscord } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const Footer: React.FC = () => {
    return (
        <footer className="bg-[#020914] text-gray-300">
            <div className="container mx-auto px-6 py-8 grid grid-cols-1 md:grid-cols-12 gap-8 text-sm md:text-base">
                <div className="md:col-span-4 flex flex-col justify-between">
                    <div>
                        <img src="/assets/images/logo.png" alt="Logo" className="h-40 mb-3" />
                        <div className="flex">
                            <a href="#" className="mr-3 hover:text-white">
                                <FaFacebook size={24} />
                            </a>
                            <a href="#" className="mr-3 hover:text-white">
                                <FaTwitter size={24} />
                            </a>
                            <a href="#" className="mr-3 hover:text-white">
                                <FaInstagram size={24} />
                            </a>
                            <a href="#" className="mr-3 hover:text-white">
                                <FaTiktok size={24} />
                            </a>
                            <a href="#" className="hover:text-white">
                                <FaDiscord size={24} />
                            </a>
                        </div>
                    </div>
                </div>

                <div className="md:col-start-6 md:col-span-2">
                    <h5 className="text-white text-lg font-bold mb-3">Contas Smurf</h5>
                    <ul className="space-y-2">
                        <li><Link to="/politica-privacidade" className="hover:text-white">Políticas de Privacidade</Link></li>
                        <li><Link to="/#products" className="hover:text-white">Produtos</Link></li>
                        <li><Link to="/#about" className="hover:text-white">Sobre Nós</Link></li>
                        <li><Link to="/#faq" className="hover:text-white">FAQ</Link></li>
                        <li><Link to="/#blog" className="hover:text-white">BLOG</Link></li>

                    </ul>
                </div>
                <div className="md:col-span-2">
                    <h5 className="text-white text-lg font-bold mb-3">Produtos</h5>
                    <ul className="space-y-2">
                        <li><Link to="/#products" className="hover:text-white">Contas de League of Legends</Link></li>
                        <li><Link to="/#products" className="hover:text-white">Outros</Link></li>
                    </ul>
                </div>
            </div>
            <div className="border-t border-gray-800 text-center py-4 mt-8">
                <div className="container mx-auto px-6 md:px-32">
                    <p className="text-xs md:text-sm text-gray-500">
                    ContaSmurf não é endossado pela Riot Games e não reflete os pontos de vista ou opiniões da Riot Games ou de qualquer pessoa oficialmente envolvida na produção ou gerenciamento de seus produtos.</p>
                </div>
                <br />
            </div>
        </footer>
    );
};

export default Footer;
