import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { FaEdit, FaTrash } from "react-icons/fa";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import "./styles.css";
import { toast } from 'react-toastify';
import axios from "axios";
import { useNavigate } from "react-router-dom";

interface TableData {
    id: number;
    name: string;
    category: string;
    region: string;
    status: string;
    account_type: string;
    price: number;
    image: string;
    created_at: string;
    updated_at: string
    priority: number;
}

const AdminProducts: React.FC = () => {
    const navigate = useNavigate();

    const [showEditModal, setShowEditModal] = useState(false);
    const [showAddModal, setShowAddModal] = useState(false);

    const [categoriaAdd, setCategoriaAdd] = useState('LoL');
    const [nomeAdd, setNomeAdd] = useState('');
    const [precoAdd, setPrecoAdd] = useState(0.0);
    const [regionAdd, setRegionAdd] = useState('BR');
    const [typeAdd, setTypeAdd] = useState('RANKED');
    const [statusAdd, setStatusAdd] = useState('ATIVAS');
    const [imagemAdd, setImagemAdd] = useState('')
    const [prioridadeAdd, setPrioridadeAdd] = useState(0)


    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [currentItem, setCurrentItem] = useState<TableData | null>(null);

    const [data, setData] = useState<TableData[]>([]);
    const regions = ["BR", "NA", "EUW", "EUNE", "LAS", "LAN", "RU", "TR", "OCE", "JP", "KR", "TW", "VN", "TH", "SG", "PH"]
    const types = ["RANKED", "CHAMPIONS", "RP", "SKINS"]
    const status = ["ATIVAS", "INATIVAS", "EM ESTOQUE"]

    const removeCookie = (name: string) => {
        document.cookie = name + '=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/';
    };
    useEffect(() => {
        const checkToken = async () => {
            const token = document.cookie.split('; ').find(row => row.startsWith('userToken='));
            if (token) {
                try {
                    const tokenValue = token.split('=')[1];
                    const result = await axios.post('https://api.contasmurf.com.br/token/validate', { token: tokenValue });

                    if (result.status !== 200) {
                        removeCookie('userToken')
                        navigate('/admin')
                    }
                } catch (error) {
                    removeCookie('userToken')
                    navigate('/admin')
                }
            } else {
                navigate('/admin')
            }
        };
        checkToken();
    }, []);

    const token = document.cookie.split('; ').find(row => row.startsWith('userToken='))
    const tokenValue = token?.split('=')[1]

    const config = {
        headers: { Authorization: `Bearer ${tokenValue}` }
    };

    useEffect(() => {
        axios.get('https://api.contasmurf.com.br/products', config).then((result) => {
            setData(result.data)
        })
    }, [])

    const handleEdit = (item: any) => {
        setCurrentItem(item);
        setShowEditModal(true);
    };

    const handleDelete = (item: any) => {
        setCurrentItem(item);
        setShowDeleteModal(true);
    };

    const handleSaveEdit = () => {
        console.log(currentItem?.priority)
        axios.patch(`https://api.contasmurf.com.br/products/${currentItem?.id}`, {
            name: currentItem?.name,
            category: currentItem?.category,
            region: currentItem?.region,
            status: currentItem?.status,
            account_type: currentItem?.account_type,
            image: currentItem?.image,
            price: currentItem?.price,
            priority: currentItem?.priority !== undefined ? currentItem?.priority : 0
        }, config).then((response) => {
            if (response.status === 200) {
                setShowEditModal(false);
                setCurrentItem(null)
                toast.success("Produto editado com sucesso!");
                axios.get('https://api.contasmurf.com.br/products').then((result) => {
                    setData(result.data)
                })
            } else {
                toast.error("Um erro ocorreu ao atualizar (STATUS: " + response.status + ")")
            }
        })
    };
    const handleSaveAdd = () => {
        if (categoriaAdd === "Outros") {
            if (precoAdd && nomeAdd) {

                axios.post('https://api.contasmurf.com.br/products', {
                    name: nomeAdd,
                    category: 'Outros',
                    region: '',
                    status: '',
                    account_type: '',
                    image: imagemAdd,
                    price: precoAdd,
                    priority: prioridadeAdd,
                }, config).then((response) => {
                    if (response.status === 201) {
                        setShowAddModal(false);
                        setCategoriaAdd('LoL')
                        setPrecoAdd(0)
                        setNomeAdd('')
                        setImagemAdd('')
                        toast.success("Produto adicionado com sucesso!");
                        axios.get('https://api.contasmurf.com.br/products').then((result) => {
                            setData(result.data)
                        })
                    } else {
                        toast.error("Um erro ocorreu ao adicionar (STATUS: " + response.status + ")")
                    }
                })
            } else {
                toast.error("Você deve preencher todos os campos para adicionar um produto!")
            }
        } else if (categoriaAdd === "LoL") {
            if (precoAdd && nomeAdd && typeAdd && statusAdd && regionAdd) {

                axios.post('https://api.contasmurf.com.br/products', {
                    name: nomeAdd,
                    category: 'LoL',
                    region: regionAdd,
                    image: imagemAdd,
                    status: statusAdd,
                    account_type: typeAdd,
                    price: precoAdd,
                    priority: prioridadeAdd,
                }, config).then((response) => {
                    if (response.status === 201) {
                        setShowAddModal(false);
                        setCategoriaAdd('LoL')
                        setPrecoAdd(0)
                        setNomeAdd('')
                        setRegionAdd('BR')
                        setStatusAdd('ATIVAS')
                        setTypeAdd('RANKED')
                        setImagemAdd('')
                        toast.success("Produto adicionado com sucesso!");
                        axios.get('https://api.contasmurf.com.br/products').then((result) => {
                            setData(result.data)
                        })
                    } else {
                        toast.error("Um erro ocorreu ao adicionar (STATUS: " + response.status + ")")
                    }
                })
            } else {
                toast.error("Você deve preencher todos os campos para adicionar um produto!")
            }
        }

    };
    const confirmDelete = () => {
        axios.delete(`https://api.contasmurf.com.br/products/${currentItem?.id}`, config).then((result) => {
            if (result.status === 204) {
                toast.error("Produto excluído com sucesso!");
                setData(data.filter(item => item.id !== currentItem?.id))
            } else {
                toast.error("Erro ao excluir o produto!");
            }
        })
        setCurrentItem(null)
        setShowDeleteModal(false)
    };
    function handleFileChange(event: any) {
        const file = event.target.files[0];
        if (file.type !== 'image/png' && file.type !== 'image/gif') {
            toast.error('Por favor, selecione um arquivo PNG ou GIF.');
            event.target.value = '';
        } else {
            const reader = new FileReader();
            reader.onloadend = () => {
                setImagemAdd(reader.result as string);
            };
            reader.readAsDataURL(file);
        }
    }

    function handleFileChangeEdit(event: any) {
        const file = event.target.files[0];
        if (file.type !== 'image/png' && file.type !== 'image/gif') {
            toast.error('Por favor, selecione um arquivo PNG ou GIF.');
            event.target.value = '';
        } else {
            const reader = new FileReader();
            reader.onloadend = () => {
                if (currentItem) {
                    const base64 = reader.result as string;
                    if (base64) {
                        setCurrentItem({ ...currentItem, image: base64 });
                    }
                }
            }
            reader.readAsDataURL(file);
        }
    };

    const columns = [
        {
            name: "ID",
            selector: (row: TableData) => row.id.toString(),
        },
        {
            name: "Nome",
            selector: (row: TableData) => row.name,
        },
        {
            name: "Imagem",
            cell: (row: TableData) => {
                return row.image ? <img src={`${row.image}`} alt={row.name} style={{ width: '60px', height: '60px' }} /> : "Sem imagem";
            },
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
        {
            name: "Categoria",
            selector: (row: TableData) => row.category,
        },
        {
            name: "Região",
            selector: (row: TableData) => row.region,
        },
        {
            name: "Status da Conta",
            selector: (row: TableData) => row.status,
        },
        {
            name: "Tipo da Conta",
            selector: (row: TableData) => row.account_type,
        },
        {
            name: "Preço (R$)",
            selector: (row: TableData) => "R$ " + row.price.toString(),
        },
        {
            name: "Prioridade",
            selector: (row: TableData) => row.priority,
        },
        {
            name: "Data de Criação",
            selector: (row: TableData) => {
                const dateString = row.created_at;
                const date = new Date(dateString);
                const formattedDate = `${("0" + date.getDate()).slice(-2)}/${("0" + (date.getMonth() + 1)).slice(-2)}/${date.getFullYear()} ${("0" + date.getHours()).slice(-2)}:${("0" + date.getMinutes()).slice(-2)}`;
                return formattedDate;
            },
        },
        {
            name: "Ações",
            cell: (row: TableData) => (
                <div className="space-x-2">
                    <button
                        onClick={() => handleEdit(row)}
                        className="text-blue-600 hover:text-blue-800"
                    >
                        <FaEdit />
                    </button>
                    <button
                        onClick={() => handleDelete(row)}
                        className="text-red-600 hover:text-red-800"
                    >
                        <FaTrash />
                    </button>
                </div>
            ),
        },
    ];

    const [searchText, setSearchText] = useState("");

    const filteredData = data.filter((item: TableData) =>
        item.name.toLowerCase().includes(searchText.toLowerCase())
    );

    return (
        <div className="w-full h-full flex flex-col items-center">
            {/* Modal de Inclusão */}
            <Modal show={showAddModal} onHide={() => setShowAddModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Adicionar Produto</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3" controlId="formProductName">
                            <Form.Label>Nome do Produto</Form.Label>
                            <Form.Control onChange={(e) => setNomeAdd(e.target.value)} type="text" placeholder="Nome do produto" />

                            <Form.Label>Categoria</Form.Label>
                            <Form.Select onChange={(e) => setCategoriaAdd(e.target.value)} defaultValue="LoL">
                                <option value="LoL">LoL</option>
                                <option value="Outros">Outros</option>
                            </Form.Select>


                            {categoriaAdd === "LoL" && (
                                <>
                                    <Form.Label>Região</Form.Label>
                                    <Form.Select onChange={(e) => setRegionAdd(e.target.value)} defaultValue="BR">
                                        {regions.map((region) => (
                                            <option key={region} value={region}>{region}</option>
                                        ))}
                                    </Form.Select>

                                    <Form.Label>Status da Conta</Form.Label>
                                    <Form.Select onChange={(e) => setStatusAdd(e.target.value)} defaultValue="ATIVAS">
                                        {status.map((status) => (
                                            <option key={status} value={status}>{status}</option>
                                        ))}
                                    </Form.Select>

                                    <Form.Label>Tipo de Conta</Form.Label>
                                    <Form.Select onChange={(e) => setTypeAdd(e.target.value)} defaultValue="RANKED">
                                        {types.map((type) => (
                                            <option key={type} value={type}>{type}</option>
                                        ))}
                                    </Form.Select>
                                </>
                            )}
                            <Form.Group className="mb-3" controlId="formPostImage">
                                <Form.Label>Imagem do Produto</Form.Label>
                                <Form.Control type="file" accept="image/png, image/gif" onChange={handleFileChange} />
                            </Form.Group>

                            <Form.Label>Preço</Form.Label>
                            <Form.Control onChange={(e) => setPrecoAdd(Number.parseFloat(e.target.value))} type="number" placeholder="Preço" />

                            <Form.Label>Prioridade de Exibição</Form.Label>
                            <Form.Control onChange={(e) => setPrioridadeAdd(Number.parseInt(e.target.value))} type="number" placeholder="Prioridade de Exibição" />

                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowAddModal(false)}>Cancelar</Button>
                    <Button variant="primary" onClick={handleSaveAdd}>Adicionar Produto</Button>
                </Modal.Footer>
            </Modal>

            {/* Modal de Edição */}
            <Modal show={showEditModal} onHide={() => setShowEditModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Editar Venda</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3" controlId="formProductName">
                            <Form.Label>Nome do Produto</Form.Label>
                            <Form.Control onChange={(e) => {
                                if (currentItem) {
                                    setCurrentItem({ ...currentItem, name: e.target.value });
                                }
                            }}
                                type="text"
                                placeholder="Produto"
                                defaultValue={currentItem?.name}
                            />

                            <Form.Label>Categoria</Form.Label>
                            <Form.Select
                                key={currentItem?.category || 'default'}
                                onChange={(e) => {
                                    if (currentItem) {
                                        setCurrentItem({ ...currentItem, category: e.target.value });
                                    }
                                }}
                                defaultValue={currentItem?.category || 'LoL'}
                            >
                                <option value="LoL">LoL</option>
                                <option value="Outros">Outros</option>
                            </Form.Select>

                            {currentItem?.category === "LoL" && (
                                <>
                                    <Form.Label>Região</Form.Label>
                                    <Form.Select onChange={(e) => setCurrentItem({ ...currentItem, region: e.target.value })} defaultValue={currentItem?.region}>
                                        {regions.map((region) => (
                                            <option key={region} value={region}>{region}</option>
                                        ))}
                                    </Form.Select>

                                    <Form.Label>Status da Conta</Form.Label>
                                    <Form.Select onChange={(e) => setCurrentItem({ ...currentItem, status: e.target.value })} defaultValue={currentItem?.status}>
                                        {status.map((status) => (
                                            <option key={status} value={status}>{status}</option>
                                        ))}
                                    </Form.Select>

                                    <Form.Label>Tipo de Conta</Form.Label>
                                    <Form.Select onChange={(e) => setCurrentItem({ ...currentItem, account_type: e.target.value })} defaultValue={currentItem?.account_type}>
                                        {types.map((type) => (
                                            <option key={type} value={type}>{type}</option>
                                        ))}
                                    </Form.Select>
                                </>
                            )}
                            <Form.Group className="mb-3" controlId="formPostImage">

                                <Form.Group className="mb-3" controlId="formPostImage">
                                    <Form.Label>Imagem do Produto</Form.Label>
                                    <Form.Control type="file" accept="image/png, image/gif" onChange={handleFileChangeEdit} />
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="formPostImagePreview">
                                    <Form.Label>Imagem Atual do Produto</Form.Label>
                                    <div>
                                        {currentItem?.image && (
                                            <img src={`${currentItem.image}`} alt="Imagem Atual" style={{ width: '100px', height: '100px' }} />
                                        )}
                                    </div>
                                </Form.Group>

                            </Form.Group>

                            <Form.Label>Preço</Form.Label>
                            <Form.Control
                                onChange={(e) => {
                                    if (currentItem) {
                                        setCurrentItem({ ...currentItem, price: Number.parseFloat(e.target.value) });
                                    }
                                }}
                                type="number"
                                placeholder="Preço"
                                defaultValue={currentItem?.price.toString()}
                            />

                            <Form.Label>Prioridade de Exibição</Form.Label>
                            <Form.Control
                                onChange={(e) => {
                                    if (currentItem) {
                                        setCurrentItem({ ...currentItem, priority: Number.parseInt(e.target.value) });
                                    }
                                }}
                                type="number"
                                placeholder="Prioridade de Exibição"
                                defaultValue={currentItem?.priority.toString()}
                            />

                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowEditModal(false)}>Cancelar</Button>
                    <Button variant="primary" onClick={handleSaveEdit}>Salvar Alterações</Button>
                </Modal.Footer>
            </Modal>

            {/* Modal de Confirmação de Exclusão */}
            <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirmação de Exclusão</Modal.Title>
                </Modal.Header>
                <Modal.Body>Tem certeza que deseja excluir esse produto?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>Não</Button>
                    <Button variant="danger" onClick={confirmDelete}>Sim</Button>
                </Modal.Footer>
            </Modal>

            <h1 className="text-3xl text-black font-semibold mb-4">Produtos</h1>
            <input
                type="text"
                placeholder="Pesquisar por nome do produto"
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                className="w-full px-3 py-2 border border-gray-300 text-black rounded-md mb-4"
            />
            <div className="w-full">
                <Button onClick={() => setShowAddModal(true)}>+ Novo Produto</Button>
                <DataTable
                    title="Lista de Produtos"
                    columns={columns}
                    data={filteredData}
                    pagination
                    paginationPerPage={10}
                    paginationRowsPerPageOptions={[10, 20, 50]}
                    customStyles={{
                        rows: {
                            style: {
                                backgroundColor: 'transparent',
                            },
                        },
                        headCells: {
                            style: {
                                backgroundColor: 'transparent',
                            },
                        },
                    }}
                />


            </div>
        </div>
    );

};

export default AdminProducts;
