import { FaDiscord, FaBars, FaTimes } from "react-icons/fa";
import { Link } from "react-router-dom";
import { useState } from "react";

const Header = () => {
    const [isMenuOpen, setMenuOpen] = useState(false);

    const toggleMenu = () => {
        setMenuOpen(!isMenuOpen);
    };

    return (
        <header className="absolute top-0 left-0 w-full flex items-center justify-center p-4 z-10 text-white">
            <div className="flex justify-between items-center w-full max-w-6xl px-10 relative">

                <Link to="/" className="hidden md:block">
                    <img src="/assets/images/logo.png" alt="Hermes Store Logo" className="w-40 h-40" />
                </Link>

                <div className="md:hidden">
                    {isMenuOpen ? (
                        <FaTimes className="w-8 h-8 cursor-pointer" onClick={toggleMenu} />
                    ) : (
                        <FaBars className="w-8 h-8 cursor-pointer" onClick={toggleMenu} />
                    )}
                </div>

                <div className="hidden md:block flex-col space-y-2">
                    <Link to="/#products" className="text-lg font-bold px-4 py-2 ">PRODUTOS</Link>
                    <Link to="/#about" className="text-lg font-bold px-4 py-2 ">SOBRE NÓS</Link>
                    <Link to="/#faq" className="text-lg font-bold px-4 py-2 ">FAQ</Link>
                </div>

                <div className={`md:hidden absolute top-full left-0 right-0 bg-opacity-70 backdrop-filter backdrop-blur-lg overflow-hidden transition-all duration-300 ease-in-out transform origin-top ${isMenuOpen ? 'opacity-100 max-h-screen' : 'opacity-0 max-h-0'} mt-2`}>
                    <div className="flex flex-col space-y-2">
                        <Link to="/#products" className="text-lg font-bold px-4 py-2 ">PRODUTOS</Link>
                        <Link to="/#about" className="text-lg font-bold px-4 py-2 ">SOBRE NÓS</Link>
                        <Link to="/#faq" className="text-lg font-bold px-4 py-2 ">FAQ</Link>
                    </div>
                </div>

                <a href="https://discord.gg/tWdRsDzjKV" target="_blank" rel="noreferrer" >
                    <button className="bg-[#8F8BF9] text-[#07172F] font-bold px-4 py-2 flex items-center text-lg rounded transition duration-300 hover:border border-[#8F8BF9] hover:bg-[#07172F] hover:text-[#8F8BF9] hover:border-[#8F8BF9] transform hover:scale-105" rel="noreferrer">
                        <FaDiscord className="w-6 h-6 mr-2" />
                        Discord
                    </button>
                </a>

            </div>
        </header>
    );
};

export default Header;
