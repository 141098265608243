import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { RiEyeCloseLine, RiEyeLine } from "react-icons/ri";
import axios from "axios";

const AdminLogin = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [senha, setPassword] = useState("");
  const [error, setError] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const removeCookie = (name: string) => {
    document.cookie = name + '=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/';
  };  
  useEffect(() => {
    const checkToken = async () => {
      const token = document.cookie.split('; ').find(row => row.startsWith('userToken='));
      if (token) {
        try {
          const tokenValue = token.split('=')[1];
          const result = await axios.post('https://api.contasmurf.com.br/token/validate', { token: tokenValue });

          if (result.status === 200) {
            navigate('/admin/painel')
          }else{
            removeCookie('userToken')
          }
        } catch (error) {
          removeCookie('userToken')
        }
      }
    };
  
    checkToken();
  }, []);

  const handleButtonClick = async () => {
    try {
      const result = await axios.post('https://api.contasmurf.com.br/admin/login', { email, password: senha });
      if (result.status === 200) {
        setError('')
        const now = new Date();
        now.setTime(now.getTime() + (2 * 24 * 60 * 60 * 1000));
        const expires = "expires=" + now.toUTCString();
        document.cookie = `userToken=${result.data}; ${expires}; path=/`;
        navigate('/admin/painel')
      }
    } catch (error: any) {
      if (error.response) {
        setError("E-mail ou senha incorretos.");
      } else if (error.request) {
        setError("Não foi possível conectar ao servidor.");
      } else {
        setError("Erro ao fazer login.");
      }
    }
  }
  

  return (
    <div className="flex h-screen">
      <div className="w-2/5 bg-gradient-to-r from-[#113366] to-[#020914]">
        <div className="flex flex-col items-center justify-center h-full">
          <div className="mb-8">
            <img className="w-48 h-auto" src="/assets/images/logo.png" alt="Logo" />
          </div>
        </div>
      </div>
      <div className="w-3/5 bg-[#020914] flex items-center justify-center">
        <div className="w-96 p-6 rounded-lg border border-[#07172F]">
          <h1 className="text-4xl p-8 text-white mb-6 text-center font-semibold">LOGIN ADMINISTRATIVO</h1>
          <form onSubmit={(e) => { e.preventDefault(); }}>
            <div className="mt-6">
              <label htmlFor="email" className="text-sm text-gray-300">DIGITE SEU E-MAIL</label>
              <input
                type="text"
                placeholder="Digite seu e-mail"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="w-full px-4 py-2 mt-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500 text-black"
              />
            </div>

            <div className="mt-4">
              <label htmlFor="pass" className="text-sm text-gray-300">DIGITE SUA SENHA</label>
              <div className="relative">
                <input
                  type={showPassword ? "text" : "password"}
                  placeholder="Digite sua senha"
                  value={senha}
                  onChange={(e) => setPassword(e.target.value)}
                  className="w-full px-4 py-2 mt-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500 text-black"
                />
                <button
                  type="button"
                  onClick={togglePasswordVisibility}
                  className="absolute top-1/2 right-4 transform -translate-y-1/2 cursor-pointer text-gray-500"
                >
                  {showPassword ? <RiEyeCloseLine /> : <RiEyeLine />}
                </button>
              </div>
            </div>

            <div className="mt-6">
              <button onClick={handleButtonClick} className="bg-[#8F8BF9] text-[#07172F] font-bold px-6 py-3 flex items-center justify-center text-lg rounded-lg transition duration-300 hover:border border-[#8F8BF9] hover:bg-[#07172F] hover:text-[#8F8BF9] hover:border-[#8F8BF9] transform hover:scale-105 w-full">
                Entrar
              </button>
            </div>
            {error && (
              <div className="mt-4 text-red-500">
                <p className="text-center">{error}</p>
              </div>
            )}
          </form>
        </div>
      </div>
    </div>
  );
}

export default AdminLogin;
