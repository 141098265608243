import React, { FC, ReactNode, useEffect } from 'react';
import { useSpring, animated } from 'react-spring';
import { useInView } from 'react-intersection-observer';
import Header from '../../components/Header';
import Footer from '../../components/Footer';

type AnimatedComponentProps = {
    children: ReactNode;
};

const AnimatedComponent: FC<AnimatedComponentProps> = ({ children }) => {
    const [ref, inView] = useInView({
        triggerOnce: true,
        rootMargin: '-100px 0px',
    });

    const animation = useSpring({
        opacity: inView ? 1 : 0,
        transform: inView ? 'translateY(0)' : 'translateY(100px)',
        config: { mass: 5, tension: 1000, friction: 80 },
    });

    return <animated.div ref={ref} style={animation}>{children}</animated.div>;
};

const PoliticasPrivacidade = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <Header />
            <div className="relative min-h-screen bg-[#07172f] flex items-center justify-center">
                <AnimatedComponent>
                    <div className="text-white max-w-[600px] mt-60 mx-auto px-4 mb-40">
                        <h1 className="text-4xl font-bold mb-4">Política de Privacidade</h1>
                        <br/>
                        <p className="text-lg">
                            Durante sua interação com a nossa plataforma, coletamos dados pessoais para poder prestar serviços de pagamento entre o MercadoPago e o nosso site. Esses dados incluem nome, e-mail, CPF e número de telefone. Essas informações são utilizadas para iniciar a prestação de nossos serviços, viabilizar a compra e venda de produtos, atender aos requisitos regulatórios e prevenir fraudes na plataforma.
                            <br/><br/>
                            É importante ressaltar que todos os dados coletados são enviados de forma criptografada para o MercadoPago Soluções de Pagamentos LTDA e não são armazenados em nossos sistemas. Estamos em conformidade com a Lei Geral de Proteção de Dados (LGPD), Lei nº 13.709/2018, vigente no território nacional do Brasil.
                            <br/><br/>
                            Além disso, para melhorar nossos produtos e publicidade, utilizamos o Microsoft Clarity para analisar como os usuários utilizam nosso site. Ao utilizar nosso site, você concorda que nós e a Microsoft podemos coletar e utilizar esses dados. Trabalhamos em parceria com a Microsoft Clarity e a Microsoft Advertising para capturar informações sobre como os usuários interagem com nosso site, como métricas comportamentais, mapas de calor e repetições de sessão. Essas informações são usadas para aprimorar e comercializar nossos produtos e serviços.
                            <br/><br/>
                            Utilizamos cookies primários e de terceiros, bem como outras tecnologias de rastreamento, para capturar dados de uso do site. Essas informações são utilizadas para determinar a popularidade de produtos/serviços, otimizar o site, prevenir fraudes/segurança e aprimorar a publicidade.
                        </p>

                    </div>
                </AnimatedComponent>
            </div>
            <Footer />
        </>
    );
};

export default PoliticasPrivacidade;
