import React from 'react';
import { FaUsers, FaStar, FaLock } from 'react-icons/fa';

type AboutCardProps = {
  icon: JSX.Element;
  title: string;
  description: string;
};

const AboutCard: React.FC<AboutCardProps> = ({ icon, title, description }) => {
  return (
    <div className="bg-[#0E233B] text-white p-6 rounded-lg shadow-lg flex flex-col items-center">
      <div className="text-3xl mb-4">{icon}</div>
      <h3 className="text-1xl font-bold mb-6">{title}</h3>
      <p className="text-center">{description}</p>
    </div>
  );
};

const AboutUs: React.FC = () => {
  return (
    <div id="about" className="w-3/4 mx-auto my-16 text-white">
      <h2 className="text-3xl text-center font-bold mb-10">SOBRE NÓS</h2>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
        <AboutCard
          icon={<FaUsers />}
          title="Nos preocupamos com você!"
          description="O nosso foco é entregar um serviço incrível a todos os nossos clientes. A ContaSmurf vende produtos de alta qualidade e possuímos inúmeros feedbacks positivos. "
        />
        <AboutCard
          icon={<FaStar />}
          title="Sua satisfação é essencial!"
          description="Desde o início a ContaSmurf tem sido o portal definitivo de League of Legends. A nossa dedicação é focada em entregar um serviço excepcional e suporte em tempo real. Eleve o seu jogo com a nossas contas de alto nível cuidadosamente escolhidas!"
        />
        <AboutCard
          icon={<FaLock />}
          title="Sua confiança, Nossa Missão!"
          description="Fundada em 2023, conquistamos sua confiança por meio da garantia de qualidade com os nossos produtos e serviços, garantindo uma jornada de jogo incomparável. Explore o nosso site, temos tudo para garantir uma melhor experiência no League of Legends."
        />
      </div>
    </div>
  );
};

export default AboutUs;
