import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import Home from './pages/Home';
import AdminLogin from './pages/AdminLogin';
import AdminPanel from './pages/Admin';
import Blog from './pages/Blog';
import Posts from './pages/Posts';
import PoliticasPrivacidade from './pages/PoliticasPrivacidade';
import { ToastContainer } from 'react-toastify';
import { useEffect } from 'react';
import Success from './pages/Success';

function App() {
  useEffect(() => {
    if (window.location.hostname.includes('.com.br')) {
      const newLocation = `https://${window.location.hostname.replace('.com.br', '.com')}${window.location.pathname}${window.location.search}`;
      window.location.href = newLocation;
    }
  }, []);
  return (
    <div className='bg-[#07172f]'>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/admin" element={<AdminLogin />} />
          <Route path="/admin/painel" element={<AdminPanel />} />
          <Route path="/blog/:id" element={<Blog />} />
          <Route path="/posts" element={<Posts />} />
          <Route path="/politica-privacidade" element={ <PoliticasPrivacidade/>} />
          <Route path="/success" element={ <Success/>} />
        </Routes>
      </Router>
      <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
    </div>
  );
}

export default App;
