import React, { FC, ReactNode, useEffect, useState } from 'react';
import { useSpring, animated } from 'react-spring';
import { useInView } from 'react-intersection-observer';
import { Link } from 'react-router-dom';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import axios from 'axios';

type AnimatedComponentProps = {
    children: ReactNode;
};
const AnimatedComponent: FC<AnimatedComponentProps> = ({ children }) => {
    const [ref, inView] = useInView({
        triggerOnce: true,
        rootMargin: '-100px 0px',
    });

    const animation = useSpring({
        opacity: inView ? 1 : 0,
        transform: inView ? 'translateY(0)' : 'translateY(100px)',
        config: { mass: 5, tension: 1000, friction: 80 },
    });

    return <animated.div ref={ref} style={animation}>{children}</animated.div>;
};
type BlogPostProps = {
    id: number,
    title: string;
    content: string;
    image: string;
    creator_name: string;
    created_at: string;
    time_to_read: string;
};

const BlogPost: React.FC<BlogPostProps> = ({ id, title, content, image, creator_name, created_at, time_to_read }) => {
    return (
        <Link to={`/blog/${id}`}>
            <div className="bg-[#0E233B] text-white p-4 rounded-lg shadow-lg flex flex-col duration-300 hover:scale-105 overflow-hidden mr-4">
                <img src={image} alt={title} className="rounded-lg w-full object-cover h-48" />
                <h3 className="text-2xl font-bold mt-4 mb-2">{title}</h3>
                <p className="text-sm mb-4" dangerouslySetInnerHTML={{ __html: content.replace(/<\/br>/g, "<br />").substring(0, 230).concat("...") }} />

                <div className="mt-auto">
                    <p className="text-sm">{creator_name}</p>
                    <p className="text-sm">
                        {new Date(created_at).toLocaleDateString('pt-BR')} · {time_to_read} min de leitura
                    </p>
                </div>
            </div>
        </Link>
    );
};
const Posts = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const [posts, setPosts] = useState<BlogPostProps[]>([]);

    useEffect(() => {
        axios.get('https://api.contasmurf.com.br/posts').then((result) => {
            setPosts(result.data)
        })
    }, [])
    return (
        <div className="flex flex-col min-h-screen">
            <Header />
            <div className="flex-grow relative bg-[#07172f] overflow-y-auto">
                <AnimatedComponent>
                    <div className="w-3/4 mt-40 mb-40 mx-auto text-white px-8">
                        <h2 className="text-4xl text-center font-bold mb-10">Todos os posts do blog</h2>
                        <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
                            {posts.map((post, index) => (
                                <BlogPost key={index} {...post} />
                            ))}
                        </div>
                    </div>
                </AnimatedComponent>
            </div>
            <Footer />
        </div>
    );
};

export default Posts;
