import AboutUs from "../../components/AboutUS";
import BlogPosts from "../../components/BlogRecentPosts";
import FAQ from "../../components/FAQ";
import Products from "../../components/Products";
import { useSpring, animated } from 'react-spring';
import { useInView } from 'react-intersection-observer';
import { FC, ReactNode, useEffect } from 'react';
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { Link, useLocation } from 'react-router-dom';

type AnimatedComponentProps = {
  children: ReactNode;
};

const AnimatedComponent: FC<AnimatedComponentProps> = ({ children }) => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    rootMargin: '-100px 0px',
  });

  const animation = useSpring({
    opacity: inView ? 1 : 0,
    transform: inView ? 'translateY(0)' : 'translateY(100px)',
    config: { mass: 5, tension: 1000, friction: 80 }
  });

  return <animated.div ref={ref} style={animation}>{children}</animated.div>;
};

const Home = () => {
  const location = useLocation();

  useEffect(() => {
    const hash = location.hash.substring(1);
    if (hash) {
      const targetElement = document.getElementById(hash);

      if (targetElement) {
        const offsetTop = targetElement.getBoundingClientRect().top + window.scrollY;
        window.scrollTo({
          top: offsetTop - 100,
          behavior: 'smooth',
        });
      }
    }
  }, [location]);

  return (
    <>
      <Header />
      <div className="relative min-h-screen bg-[#07172f]">
        <div
          className="absolute top-0 left-0 w-full h-full flex flex-col items-center justify-center bg-no-repeat bg-cover bg-center"
          style={{
            backgroundImage: "linear-gradient(to bottom, #07172fdd 0%, #07172fbb 20%, transparent 50%, #07172fbb 67%, #07172fdd 100%), url('assets/images/background.jpg')",
            padding: '4rem 35.5vw'
          }}
        >
          <div className="text-center text-white z-10 px-2"
          >
            <h2 className="text-5xl font-bold mb-6">CONTAS E PRODUTOS DE QUALIDADE!</h2>
              <Link to="/#products">
                <button className="bg-[#8F8BF9] text-[#07172F] font-bold px-6 py-2 text-lg rounded transition duration-300 hover:border border-[#8fe24a] hover:bg-[#07172F] hover:text-[#8F8BF9] hover:border-[#8F8BF9] transform hover:scale-105">
                  CHECAR OS PRODUTOS!
                </button>
              </Link>
            <Link to="#products">
            </Link>
          </div>
        </div >
      </div>
      <AnimatedComponent>
        <Products />
      </AnimatedComponent>
      <AnimatedComponent>
        <AboutUs />
      </AnimatedComponent>
      <AnimatedComponent>
        <FAQ />
      </AnimatedComponent>
      <AnimatedComponent>
        <BlogPosts />
      </AnimatedComponent>
      <Footer />
    </>
  );
};

export default Home;
